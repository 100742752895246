@import '../_colors.scss';

.rc-input-number:hover, .rc-input-number-focused  {
  border-color: $active-salmon;
}

.rc-input-number-focused {
  box-shadow: 0 0 0 2px $active-salmon;
}

.rc-input-number:hover .rc-input-number-handler-up, .rc-input-number:hover .rc-input-number-handler-wrap {
  border-color: $active-salmon;
}

.rc-input-number-input {
  width: calc(var(--input-width));
  height: var(--input-height);
  border: 1px solid #ADADAD;
  border-radius: 16px;  
}

.rc-input-number {
  height: inherit;
  border: none;
}

.rc-input-number:hover, .rc-input-number-focused {
  border: none;
  box-shadow: none;
}


span.input-note {
  display: block;
  font-size: smaller;
  margin-left: 8px;
  margin-top: -5px;
}

div.row div.column > div {
  margin-bottom: 10px;
}