


/**
 * Copyright © 2021-2022 Kytopen All Rights Reserved
 * MODULE: index.scss
 * DESCRIPTION: Primary CSS for the application.
 */

 // Start common CSS between TX and Discover. Do not change unless you also make the change in discover
 @import '_colors.scss'; 

 $element-font-size: 1.2em;
 $input-font-size: 1.2em;
 $button-font-size:  1.3em;

///////////////////////////////////////////////////////////////////////////////
//#region                     Global Properties                              //
///////////////////////////////////////////////////////////////////////////////

body {
    font-family: 'Roboto', sans-serif;
    font-size: 1.05em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #ffffff;
    height: 100%;
    width: 100%;
}

:root{
    --spacing-unit: 10px;
    --top-bottom-padding: var(--spacing-unit);
    --left-right-padding: calc(var(--spacing-unit) * 2.5);
    --input-width: 320px;
    --input-height: 46px;
}
//#endregion Global Properties

///////////////////////////////////////////////////////////////////////////////
//#region                       Grid Layout                                  //
///////////////////////////////////////////////////////////////////////////////
#page {
    display: grid;
    width: 100vw;
    height: 100vh;
    grid-template-areas: "logo1 menu head1"
                         "logo2 menu head2"
                         "logo3 menu head3"   
                         "nav  main main"
                         "navfoot  mainfoot mainfoot";
    grid-template-rows: 20px 35px 25px 1fr 44px;
    grid-template-columns: calc(230px + var(--left-right-padding) * 2) 80px 1fr;  // logo-width + padding each side
    margin: 0;
}

#page > logo1 {
    grid-area: logo1;
    background-color: $corporate-blue;
}

#page > menubar {
    grid-area: menu;
    background-color: $complementary-blue2;
    padding-right: var(--left-right-padding);
    color: #ffffff;
    font-size: 12px;
}

#page > header1 {
    grid-area: head1;
    background-color: $complementary-blue2;
    padding-right: var(--left-right-padding);
    color: #ffffff;
    font-size: 12px;
}

#page > logo2 {
    grid-area: logo2;
    background-color: $corporate-blue;
    text-align:center;
    padding-right: var(--left-right-padding);
    padding-left: var(--left-right-padding);
}

#page > header2 {
    grid-area: head2;
    background-color: $complementary-blue2;
    color: #ffffff;
    text-align: center;
    font-size:35px;
}
  
#page > logo3 {
    grid-area: logo3;
    background-color: $corporate-blue;
}

#page > header3 {
    grid-area: head3;
    background-color: $complementary-blue2;
    padding-right: var(--left-right-padding);
    color: #ffffff;
    font-size: 14px;
}

#page > nav {
    grid-area: nav;
    background-color: #EFEFEF;
    padding-right: var(--left-right-padding);
    padding-left: var(--left-right-padding);
    padding-top: var(--top-bottom-padding);
}

#page > main {
    position:relative;  // position must be relative to bottom align div.page-buttons using position absolute
    grid-area: main;
    padding-right: var(--left-right-padding);
    padding-left: var(--left-right-padding);
    padding-top: var(--top-bottom-padding);
}

#page > navfooter {
    grid-area: navfoot;
    background-color: #EFEFEF;
    color: #9597A6;
    text-align: center;
    padding-top: var(--top-bottom-padding);
}

#page > mainfooter {
    grid-area: mainfoot;
}
//#endregion Grid Layout

///////////////////////////////////////////////////////////////////////////////
//#region                         Header                                     //
///////////////////////////////////////////////////////////////////////////////

// Class for triple-bar menu icon container in header
.header-menu {
    padding-top: var(--top-bottom-padding);
    padding-left: var(--left-right-padding);
    float: left;
    min-height: 80px;
    width: 100%;
}

// Class for datetime container in header
.header-clock {
    padding-top: var(--top-bottom-padding);
    float: right;
    // border: 1px solid black;
}

// User ID box button class in header
.header-user-box {
    float:right;
    border: 1px solid white;
    cursor: pointer;
    padding: 4px;
    // https://getcssscan.com/css-box-shadow-examples
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
//#endregion Header

///////////////////////////////////////////////////////////////////////////////
//#region                    Flex Row / Cols Layout                          //
///////////////////////////////////////////////////////////////////////////////
.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
}

.double-column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 2;
}
//#endregion Flex Rows / Cols Layout

///////////////////////////////////////////////////////////////////////////////
//#region                       Standard Elements                            //
///////////////////////////////////////////////////////////////////////////////

table {
    width: 100%;
    font-size: $element-font-size;
}

tbody.unselectable {
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

th {
    color: black;
    text-align: left;
    padding-bottom: var(--top-bottom-padding);
    background: white;
    padding: 8px 2px;
}

td {
    padding: 8px 2px;
    cursor: pointer;
}

tr:nth-child(even) {
    background-color: #EFEFEF;
}

h1 {
    font-size: 1.3em;
    padding-bottom: var(--top-bottom-padding);
    margin-bottom: 5px;
    cursor: pointer;

    &.active{
        background-color: $complementary-blue2;
        color: white;
    }
}


input:not([type="file"]){
    color: black;
    font-size: $input-font-size;
    display: block;

    box-sizing: border-box;
    width: var(--input-width);
    height: var(--input-height);
    padding: 0px 12px;
    margin-bottom: 10px;
    border: 1px solid #ADADAD;
    border-radius: 16px;

    &::placeholder {
        color: black;
        opacity: 0.5;
    }

    &:focus {
        border: 2px solid $active-salmon;
        padding: 0px 11px;
        outline: none;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// enlarge radio controls and checkboxes slightly for touch-screen ease of use
input[type=radio], input[type=checkbox] { 
    transform: scale(1.5);
    margin-right: 8px;
}

select.time {
    width: 80px;
    display:inline;
    margin-right: 4px;
    margin-bottom: 0px;
}

select {
    font-size: $element-font-size;
    display: block;
    
    background-color: white;
    box-sizing: border-box;
    width: var(--input-width);
    height: var(--input-height);
    // height: var(--input-height);
    padding: 0px 12px;
    margin-bottom: 22px;
    border: 1px solid #ADADAD;
    border-radius: 16px;

    &:focus {
        border: 2px solid $active-salmon;
        padding: 0px 11px;
        outline: none;
    }
}

textarea {
    color: black;
    font-family: inherit;
    font-size: $element-font-size;
    width: var(--input-width);
    display: block;
    height: calc(var(--input-height) * 2.3);
    background-color: white;
    box-sizing: border-box;
    padding-top:12px;
    padding-left: 12px;;
    margin-bottom: 16px;
    border: 1px solid #ADADAD;
    border-radius: 16px;
    resize: none;

    &:focus {
        border: 2px solid $active-salmon;
        padding: 0px 11px;
        outline: none;
    }
}

textarea.slim-text-area{
    height:  var(--input-height);
    border-radius: 16px 16px 16px 16px;
    width: calc(var(--input-width)*2.5);
}

label,legend {
    color: #ADADAD;
    font-size: $element-font-size;

    margin-bottom: 8px;
    display: block;
    margin-right: 10px;
}

.superscript {
    vertical-align: super;
    font-size: smaller;
}

//#endregion Standard Elements

///////////////////////////////////////////////////////////////////////////////
//#region                          Buttons                                   //
///////////////////////////////////////////////////////////////////////////////

// Menu button class
.menu-button-box {
    margin-top: 20px;
    padding: 10px;
    font-size: $element-font-size;
    text-align: center;
    border: 1px solid black;
    cursor: pointer;
    // https://getcssscan.com/css-box-shadow-examples
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    
    button {
        background-color: white;
        border: none;
        color: black;
        padding: 0px;
        margin: 0px;
        height: 100%;
    }
}



button {
    color: #ffffff;
    background-color: $active-salmon;
    font-size: 1em;
    box-sizing: border-box;
    padding-left: calc(var(--spacing-unit)* 2);
    padding-right: calc(var(--spacing-unit)* 2);
    margin-top: 15px;
    height: 45px;
    min-width: 140px;
    border: 0px solid $active-salmon;;
    border-radius: 28px;
    cursor: pointer;
    &:disabled {
        background-color: #54587190;
        border: 0px solid #54587190;
        cursor: not-allowed;
    }
}

button.secondary-button, button.cancel-button {
    background-color: white;
    color: $corporate-blue;
    border: 3px solid $active-salmon;

    &:disabled {
        border: 3px solid #54587180;
    }
}

// Page buttons differs from discover in that these are pushed to the bottom and on discover they are centered
.page-buttons {
    position: absolute; // required to position button at bottom of parent container, <main />
    bottom: 0px;
    left: var(--left-right-padding);
    right: var(--left-right-padding);
}

.standard-button {
    float: right;
    margin-left: calc(var(--spacing-unit) * 2);
}

.cancel-button {
    float:left;
}

.stop-button {
    margin-left: 41%; // centers the button
    background-color: #e86d5b;
    border: 1px solid #e86d5b;
}

.stop-button-confirm {
    float: right;
    margin-left: calc(var(--spacing-unit) * 2);
    background-color: #e86d5b;
    border: 1px solid #e86d5b;
}

.start-button {
    float: right;
    margin-left: calc(var(--spacing-unit) * 2);
    background-color: #3fbd3f;
    border: 1px solid #3fbd3f;
}

.custom-controls-button {
    margin-left: 22%;
    background-color: #ff9900;
    border: 1px solid #ff9900;
}

.pump-control-button {
    padding-top:  calc(var(--spacing-unit) * 2);
    padding-bottom:  calc(var(--spacing-unit) * 2);
    background-color: #ff9900;
    border: 1px solid #ff9900;
    // override some default button attributes to support larger button
    min-height:100px;
    border-radius: 50px;
}

//#endregion Buttons

///////////////////////////////////////////////////////////////////////////////
//#region                      Test In Progress                              //
///////////////////////////////////////////////////////////////////////////////

.testinprogress-container {
    vertical-align: top;
    text-align: center;
    color: $corporate-blue;
}

#testinprogress-countdown {
    font-size: 2em;
    min-width: 300px;
    padding-bottom: calc(var(--top-bottom-padding) * 4);

    &[animated] {
        animation: kytopencolorsanimation 5s linear infinite;
    }
}

@keyframes kytopencolorsanimation {
    0% { color: #ff87ff; }
    6% { color: #cda0fe; }
    12% { color: #9e9efe; }
    18% { color: #729eff; }
    25% { color: #5cb3fe; }
    31% { color: #739efe; }
    37% { color: #9d9dff; }
    43% { color: #cda0fe; }
    50% { color: #fe86fe; }
    56% { color: #fe86c6; }
    62% { color: #fe868b; }
    68% { color: #fe9459; }
    75% { color: #fe9801; }
    81% { color: #fe9359; }
    87% { color: #ff878b; }
    93% { color: #fe86c6; }
    100% { color: #ff87ff; }
}

#progressbar {
    margin-left:152px;
    padding-top: calc(var(--spacing-unit) * 6);
    padding-bottom: calc(var(--spacing-unit) * 10);
}
//#endregion Test In Progress

///////////////////////////////////////////////////////////////////////////////
//#region                          Results                                   //
///////////////////////////////////////////////////////////////////////////////

#results-more-details {
    padding: 12px;

    > summary {
        color: $active-salmon;
        cursor: pointer;
        padding: 2px 0px;
    }

    summary:focus {
        span {
            text-decoration: underline;
        }
    }

    border-radius: 16px;
    &[open] {
        border: 1px solid #adadad;
    }

    .warningId {
        color: $goldenrod;
    }

    li {
        text-align: left;
        list-style: none;
        margin: var(--top-bottom-padding);
    }
}
//#endregion Results

///////////////////////////////////////////////////////////////////////////////
//#region                         Test Info                                  //
///////////////////////////////////////////////////////////////////////////////

// Used by setupinfo and results pages
.testinfo-container {
    flex-grow: 0.25;

    h3 {
        color: #ADADAD;
        font-size: 1.2em;
        margin: var(--spacing-unit) 0px;
        padding-top: var(--spacing-unit);
    }

    p {
        font-size: 1.2em;
        font-weight: 500;   // slightly bolded
        margin: var(--spacing-unit) 0px;
    }


    &[interactive] p {
        color: $active-salmon;
    }
}
//#endregion Test Info

///////////////////////////////////////////////////////////////////////////////
//#region                      Worflow Nav                                   //
///////////////////////////////////////////////////////////////////////////////

#workflow-nav {

    h2 {
        cursor: pointer;
        padding-left: var(--spacing-unit);
        font-size: 1.2em;
        text-align: center;

        &.active{
            background-color: $complementary-blue2;
            color: white;
        }
    }

    ul {
        padding-left: var(--spacing-unit);
    }

    li {
        padding-top: calc(var(--spacing-unit) / 2);
        list-style: none;
    }

    section {
        margin-top: var(--top-bottom-padding);
    }
}

// Class for completed item in list
.item-completed {
    color: #808080;
}
// Class for inactive item in list
.item-inactive {
    color: #808080;
}

//#endregion

///////////////////////////////////////////////////////////////////////////////
//#region                           Misc                                     //
///////////////////////////////////////////////////////////////////////////////

// Used by userform
#roleoption-wrapper {
    color: #ADADAD;
    font-size: 1.2em;

    box-sizing: border-box;
    // height: 80px;
    width: 608px;
    margin-bottom: 24px;
    padding: 12px 16px 12px 12px;
    border: 1px solid #ADADAD;
    border-radius: 16px;

    &>label {
        text-align: center;
        padding: 0px 24px;
    }
}

// for displaying check mark
.checkmark {
    display: flex;
    align-items: center;
    white-space: pre-wrap;
    padding-bottom: calc(var(--spacing-unit) * 0.5);

    &::before {
        content: "\2713 ";
        font-size: 1.2em;
        margin: 0px 2px;
    }
}

// alert warning/info message
.alert {
    color: $goldenrod;
    display: flex;
    align-items: center;
    white-space: pre-wrap;
    padding-bottom: calc(var(--spacing-unit) * 0.5);

    &::before {
        content: "⚠ ";
        font-size: 1.2em;
        margin: 0px 2px;
    }
}

// an alert not to be ignored
.extreme-alert {
    color: #e86d5b;
    display: flex;
    align-items: center;
    white-space: pre-wrap;
    padding-bottom: calc(var(--spacing-unit) * 0.5);

    &::before {
        content: "⚠ ";
        font-size: 1.2em;
        margin: 0px 2px;
    }
}

// alert success message
.success {
    color: $corporate-blue;
    display: flex;
    align-items: center;
    white-space: pre-wrap;
    padding-bottom: calc(var(--spacing-unit) * 0.5);

    &::before {
        content: "\2713 ";
        font-size: 2em;
        margin: 0px 2px;
    }
}


.trademark, .emphasize {
    font-style: italic;
}

.required {
    color: red;
}

.disconnected-container, .downloading-container {
    text-align: center;

    p {
        color: $corporate-blue;
        font-size: 1.5em;
        margin: calc(var(--spacing-unit) * 3) auto;
    }
}

// Used by help page
.info-container {
    h3 {
        font-size: 1.4em;
    }

    div {
        margin-top: var(--top-bottom-padding);
        line-height: 1.5em;
        font-size:1.2em;
    }

    ul {
        list-style-type: disc;
        padding-left: calc(var(--spacing-unit) * 2);
        margin-left: var(--spacing-unit);
        margin-bottom: var(--spacing-unit);
    }
}

.data-restore-container {
    h3, label {
        font-size: 0.8em;
    }

    ul {
        list-style-type: disc;
        padding-left: calc(var(--spacing-unit) * 2);
        margin-left: var(--spacing-unit);
        margin-bottom: var(--spacing-unit);
    }

    div {
        margin-top: var(--top-bottom-padding);
        line-height: 1.2em;
        font-size:1.2em;
    }
}



.loading-spinner {
    font-size: 1.5em;
    margin: 40px 20px;
    animation: kytopencolorsanimation 5s linear infinite;
}

@keyframes kytopenspinneranimation {
    20%, 40%, 70%, 90% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.8);
    }
}

// Used by kytopen grayscale spinner
.grayscale {
    filter: grayscale(100%);
}

.pump-directions {
    font-weight: bold;
}

.slidecontainer {
    width: 100%; /* Width of the outside container */
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 30px;
    margin-top: calc(var(--spacing-unit) * 4);
    margin-bottom: calc(var(--spacing-unit) * 4);
    border-radius: 20px;  
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
}

.overflow {
    overflow-y: auto;
    max-height: 340px;
    margin-bottom: calc(var(--spacing-unit) * 3);
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 40px;
    height: 40px;
    border-radius: 50%; 
    background: #3fbd3f;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #3fbd3f;
    cursor: pointer;
}

// Used by PasswordRequirements component
.hint {
    font-size: 13px;
    margin-top: calc(var(--spacing-unit) * 2);
}
// END common CSS between TX and Discover

#history-table table {
    table-layout: fixed;
}

#history-table td, th {
    word-wrap: break-word;
}

#history-table .item-more-details {
    inline-size: 150px;
}

.inline-box {
    inline-size: 130px;
    overflow-wrap: break-word;
}

.testinfo-container p {
    word-break: break-all;
    white-space: normal;
}

//#endregion Misc

// Making it so virtual keyword doesn't match the styling of the other buttons so
// the keyboard stays big how it used to be. This can be revisted later
button.ui-keyboard-button {
    font-weight: 400;
    font-size: 1.4em;
}

// darkening the inside of a disabled radio button to distinquish that its unselectable
input[type=radio]:not(:checked):disabled {
    appearance: none;
    width: .8rem;
    height: .8rem;
    border-radius: 50%;
    background-clip: content-box;
    border: 1px solid #ADADAD;
    background-color: lightgrey;
}


div.in-place-input-validation {
    input {
        display: inline;
    }
    span.error-msg {
        display: block;
        color: $failure-red;
        font-size: $input-font-size - .3em;
        margin-left: 1em;
        min-height: 20px;
    }
    span.checkmark{
        color: $success-green;
        display: inline;
        margin-left: .3em;
    }
}

// Getting the margin below current password the same as password and confirm password
// even with the span.error-msg min-height
#passwordform-currentPassword {
    margin-bottom: 30px;
}

h5 {
    margin-bottom: 30px;
}