
.navbar-header {
  background-color: #F16E10;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



.sign-in{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.bg-body-tertiary {
  background-color: rgba(var(--bs-dark-rgb),var(--bs-bg-opacity))!important;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


div.modal-dialog {
  color: black;
}

.modal-content {
  background-color: white;
}
@keyframes kytopenspinneranimation {
  20%, 40%, 70%, 90% {
      transform: scale(1);
  }
  50% {
      transform: scale(1.8);
  }
}

.kytopen-logo-spinner {
  display: inline-block;
  position: relative;
  margin: 20px;

  div {
      position: absolute;
      width: 2px;
      height: 2px;
      border-radius: 50%;

      &[animated] {
          animation: kytopenspinneranimation 2.0s linear infinite;
      }
  }
  span.spinner-msg {
    margin-left: 30px;
  }
}

.btn.btn-primary[disabled] {
  background-color: lightgrey;
  border-color:lightgrey
}

.nav-item {
  white-space: nowrap;
}

.side-nav-menu {
  border-right: thin solid white
}


.home-description {
  display: flex;
  align-items: center;
}
