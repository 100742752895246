$active-pink: rgb(255, 135, 255);
$goldenrod: rgb(255, 148, 91);
$corporate-blue: rgb(38, 42, 88);
$complementary-blue1: rgb(91, 179, 255);
$complementary-blue2: rgb(115, 158, 255);
$complementary-blue3: rgb(159, 158, 255);
$complementary-blue4:rgb(206, 161, 255);
$active-salmon: rgb(255, 135, 199);
$soft-coral-complement: rgb(255,141,143);
$bright-orange-complement: rgb(255,153,0);
$success-green: lightgreen;
$failure-red: orangered;
